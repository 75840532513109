module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-35595884-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"W. Murray Butler, DPM","short_name":"WMB","start_url":"/","background_color":"#094067","theme_color":"#094067","display":"minimal-ui","icon":"src/images/wmb-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"e823899f005e29c480138d9dbda1ef18"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
